<template>
    <div>
        <b-container>
            <section v-if="getUserJobGeneric.length == 0 && loader" class="event-details mb-3">
                <Spinner size="medium" line-fg-color="#e91e63" />
            </section>
            <section v-else class="job">
                <b-row>
                    <b-col cols="12" lg="8">
                        <div class="sector sector--overflow">
                            <div v-if="getUserJob.ribbon" class="custom-ribbon custom-ribbon--right">
                                <span class="custom-ribbon__text custom-ribbon__text--green">Featured</span>
                            </div>
                            <p class="jobs__form">{{ getUserJob.type.text }}</p>
                            <h2 class="jobs__position">{{ getUserJob.position }}</h2>
                            <h3 class="job__title">Description</h3>
                            <p v-html="getUserJob.desc" class="job__description"></p>
                            <h3 class="job__title">Qualifications and skills</h3>
                            <p v-html="getUserJob.qual" class="job__description"></p>
                            <h3 class="job__title">Offer and benefits</h3>
                            <p v-html="getUserJob.offer" class="job__description"></p>
                            <b-row v-if="getUserJob.method == 'url'">
                                <b-col cols="12" md="4">
                                    <a class="details-button details-button--red" :href="getUserJob.apply"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Apply</a>
                                </b-col>
                            </b-row>
                            <b-row v-if="getUserJob.method == 'email'">
                                <b-col cols="12" md="4">
                                    <a class="details-button details-button--red" :href="'mailto:'+ getUserJob.apply"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Apply</a>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="4">
                        <div class="sector">
                            <div class="job__element mb-0">
                                <h3 class="job__subtitle">Company info</h3>
                                <span class="job__info"><simple-line-icons icon="briefcase" size="small" color="#504e70" />{{ getUserJob.company }}</span>
                                <span v-if="getUserJob.email" class="job__info"><simple-line-icons icon="envelope" size="small" color="#504e70" /><a class="job__link" :href="'mailto:'+ getUserJob.email">{{ getUserJob.email }}</a></span>
                                <span v-if="getUserJob.phone" class="job__info"><simple-line-icons icon="phone" size="small" color="#504e70" />{{ getUserJob.phone}}</span>
                                <span v-if="getUserJob.website" class="job__info"><simple-line-icons icon="globe" size="small" color="#504e70" /> <a class="job__link" :href="getUserJob.website" target="blank">website</a></span>
                                <span class="job__info"><simple-line-icons icon="locationPin" size="small" color="#504e70" />{{ getUserJob.city }}, {{ getUserJob.country.text }}</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
export default {
  components: {
    SimpleLineIcons,
    Spinner
  },
  data: function () {
    return {
      loader: true
    }
  },
  computed: {
    getUserJobGeneric () {
      return this.$store.getters.getUserJob
    },
    getUserJob () {
      return this.$store.getters.getUserJob[0]
    }
  },
  beforeCreate () {
    this.$store.dispatch('getUserJob', { id: this.$route.params.id })
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
